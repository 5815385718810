import React from "react"
import LayoutPage from "../../components/layout-page"
import SEO from "../../components/seo"
import ThankYou from "../../components/thank-you"
import ImageMeta from "../../components/ImageMeta"

const PluginThankYou = () => (
  <LayoutPage>
    <SEO title={"Thank you for your interest"} meta={ImageMeta}/>
    <ThankYou title={"Thank you for your interest in the guide"}>
      <a href={"/essential-guide-to-mastering.pdf"}>Click here to download your copy</a>
    </ThankYou>
  </LayoutPage>
)

export default PluginThankYou
